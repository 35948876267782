import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import SEO from '../components/organisms/seo';
import { media } from '../utils/media-queries';
import { LayoutContext } from '../components/organisms/layoutProvider';
import RichText from '../components/atoms/richtext';
import { convertTypes } from '../pages/projects';

const Project = ({ data, pageContext }) => {
  const { language } = useContext(LayoutContext);
  const raw = language === 'en' ? '_rawEn' : '_rawDe';

  const { title, slug, location, year, projectType, content } = data.projects;
  const nextProject = pageContext.next;

  useEffect(() => {
    navigate(
      language === 'en'
        ? `/projects/${slug.current}`
        : `/de/projects/${slug.current}`,
      { replace: true }
    );
  }, [language, slug]);

  return (
    <>
      <SEO siteTitle={title} />
      <StyledProject className="spacing-inner">
        <div className="desc">
          <h2 className="hover">
            <Link to={language === 'en' ? `/projects` : `/de/projects`}>
              {data.project.nodes[0].title[language] ||
                data.project.nodes[0].title.en}
            </Link>
          </h2>
          <br />
          <div>
            <p>{title}</p>
            <p>{location}</p>
            <p>{year}</p>
            {projectType.type.map((type, i) => (
              <p key={i}>{`–${convertTypes(type)}`}</p>
            ))}
          </div>
        </div>
        <div className="content">
          {content.map(
            (item, i) =>
              (item.__typename === 'SanityProjectCopy' && (
                <div className="copy" key={i}>
                  <RichText blocks={item.copy[raw]} />
                </div>
              )) ||
              (item.__typename === 'SanityProjectImageFull' && (
                <figure
                  key={i}
                  className="full"
                  style={{ width: item.smaller && '75%' }}
                >
                  <Img fluid={item.image.image.asset?.fluid} />
                </figure>
              )) ||
              (item.__typename === 'SanityProjectImageHalf' && (
                <div className="half" key={i}>
                  <figure
                    style={{ paddingRight: 'calc(var(--spacing-XXXS) * 2)' }}
                  >
                    <Img fluid={item.imageLeft.image?.asset.fluid} />
                  </figure>
                  <figure>
                    <Img fluid={item.imageRight.image?.asset.fluid} />
                  </figure>
                </div>
              )) ||
              (item.__typename === 'SanityVideo' && (
                <div className="video" key={i}>
                  <iframe
                    src={
                      item.url.includes('youtube')
                        ? `https://www.youtube-nocookie.com/embed/${
                            item.url.split('?v=')[1]
                          }`
                        : `https://player.vimeo.com/video/${
                            item.url.split('.com/')[1]
                          }?dnt=1`
                    }
                    title="Video"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  />
                </div>
              ))
          )}
        </div>
        <Link
          to={
            language === 'en'
              ? `/projects/${nextProject}`
              : `/de/projects/${nextProject}`
          }
        >
          <div className="next hover">
            <p className="arrow copy-small">ᐳ</p>
            <p>{`${
              language === 'en' ? 'next Project' : 'nächstes Projekt'
            }`}</p>
          </div>
        </Link>
      </StyledProject>
    </>
  );
};

const StyledProject = styled.section`
  min-height: 100vh;
  margin-top: var(--v-spacing-S);
  display: flex;
  flex-direction: column;
  font-family: var(--font-1);
  padding-bottom: var(--spacing-XL);

  .desc,
  .content {
    padding-right: var(--v-spacing-S);
  }

  .content {
    padding-top: var(--spacing-L);

    .copy {
      padding-bottom: var(--v-spacing-M);
    }

    .half,
    .full {
      padding-bottom: calc(var(--spacing-XXXS) * 2);
    }

    .half {
      display: flex;

      figure {
        width: 100%;
      }
    }
  }

  .video {
    margin-bottom: calc(var(--spacing-XXXS) * 2);
  }

  .next {
    font-family: var(--font-2);
    width: 10%;
    display: flex;
    padding-top: var(--v-spacing-M);

    .arrow {
      transform: translateY(7px);
      padding-right: var(--spacing-XXXS);
    }
  }

  @media ${media.L} {
    flex-direction: row;

    .desc {
      width: 25%;
    }

    .content {
      width: 50%;
      padding-top: 0;

      .copy {
        width: 75%;
      }
    }

    .next {
      display: flex;
      transform: translateX(calc(var(--v-spacing-L) * -1));
      padding-top: 0;

      .arrow {
        transform: translateY(13px);
        padding-right: var(--spacing-XXS);
      }
    }
  }
`;

export const query = graphql`
  query($slug: String!) {
    projects: sanityProject(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      location
      year
      projectType {
        type
      }
      content {
        ... on SanityProjectCopy {
          copy {
            _rawDe
            _rawEn
          }
        }
        ... on SanityProjectImageFull {
          image {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
          }
          smaller
        }
        ... on SanityProjectImageHalf {
          imageLeft {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
          }
          imageRight {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
          }
        }
        ... on SanityVideo {
          url
        }
      }
    }
    project: allSanityProjectsPage {
      nodes {
        title {
          de
          en
        }
      }
    }
  }
`;

Project.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default Project;
